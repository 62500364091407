import { ThemeProvider, createTheme } from "@mui/material/styles";
import { themeOptions } from "./theme/theme";
import CssBaseline from "@mui/material/CssBaseline";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/common/layout/layout";
import Home from "./components/home/home";
import NoMatch from "./components/no-match/no-match";
// import Info from "./components/info/info";
// import LineUp from "./components/line-up/line-up";
// import StewartLee from "./components/stewart-lee/stewart-lee";
// import StageTimes from "./components/stage-times/stage-times";

import "./App.css";

import "./fonts/goboldthin-webfont.woff";
import "./fonts/goboldthin-webfont.woff2";
import "./fonts/GoboldThin.otf";
// import Competition from "./components/competition/competition";

function App() {
  const theme = createTheme(themeOptions);
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            {/* <Route path="line-up" element={<LineUp />} />
            <Route path="info" element={<Info />} />
            <Route path="stewart-lee" element={<StewartLee />} />
            <Route path="stage-times" element={<StageTimes />} />
            <Route path="competition" element={<Competition />} /> */}
            <Route path="*" element={<NoMatch />} /> 
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
