import { Button, Container } from "@mui/material";
import { Link } from "react-router-dom";

function NoMatch() {
  return (
    <Container maxWidth="xl">
      <div className="no-match">
        <h1>Nothing to see here!</h1>
        <p>
          <Link to="/">
            <Button
              color="secondary"
              size="large"
              variant="contained"
              sx={{
                marginTop: "20px",
                fontSize: "24px",
                padding: "10px 50px",
                textAlign: "center",
              }}
              target="_blank"
            >
              Go to the home page
            </Button>
          </Link>
        </p>
      </div>
    </Container>
  );
}

export default NoMatch;
