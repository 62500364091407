export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#f39519",
    },
    secondary: {
      main: "#00ab99",
    },
    background: {
      default: "#dfd4be",
    },
    warning:{
      main: "#ed7aa9",
    },
    divider: "#ed7aa9",
  },
  spacing: 12,
  shape: {
    borderRadius: 0,
  },
};
