import { Button, Typography } from "@mui/material";
// import lineup from "../../images/assets/roblineup.png";
import { Link } from "react-router-dom";
import logo from "../../images/logo/logomain.png";
import videoWebm from "../../videos/bpf.webm";
import videoMp4 from "../../videos/bpf.mp4";

function Home() {
  return (
    <div className="home">
      <div className="modal-content">
        <Link className="header-logo-link logo-landing" to="/">
          <img src={logo} className="app-logo" alt="logo" />
        </Link>
        <Typography className="title" variant="h6">
          Brighton Psych Fest 2025
        </Typography>
        <div className="video-container">
          <video width="100%" autoPlay loop muted playsInline>
            <source src={videoWebm} type="video/webm" />

            <source src={videoMp4} type="video/mp4" />
          </video>
        </div>

        <Typography className="text">£24.50 early bird ticket*</Typography>
        <Typography className="text">Friday 29th August 2025</Typography>
        <Typography className="text text-small">*plus booking fees</Typography>

        <Button
          href="https://www.seetickets.com/event/brighton-psych-fest-2025/various-brighton-venues/3146372"
          color="primary"
          size="large"
          variant="contained"
          className="ticket-header-button"
          sx={{
            fontSize: "16px",
            margin: "20px 0",
            textAlign: "center",
          }}
          target="_blank"
        >
          BUY TICKETS NOW
        </Button>
      </div>

      {/* <div className="home-lineup">
        <Container maxWidth="xl">
          <div className="lineup-container lineup-container-desktop">
            <img src={lineup} className="lineup-image" alt="lineup" />
          </div>

        </Container>
      </div> */}

      {/* <div className="home-tickets">
        <Container maxWidth="xl">
          <div className="home-tickets-container">
            <Button
              href="https://www.seetickets.com/event/brighton-psych-fest/various-brighton-venues/2924482"
              color="warning"
              size="large"
              variant="contained"
              sx={{
                marginTop: "5px",
                fontSize: "28px",
                padding: "10px 40px",
                textAlign: "center",
              }}
              target="_blank"
              className="ticket-header-button"
            >
              Buy Tickets
            </Button>
          </div>
        </Container>
      </div> */}
    </div>
  );
}
export default Home;
