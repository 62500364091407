import { Outlet } from "react-router-dom";
// import ResponsiveAppBar from "../app-bar/app-bar";
import Footer from "../footer/footer";
// import Modal from "react-modal";
// import { Button, Typography } from "@mui/material";
// import { useState } from "react";

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-46%",
//     transform: "translate(-50%, -50%)",
//     border: "none",
//     borderRadius: "30px",
//   },
// };

function Layout() {
  // const [modalIsOpen, setIsOpen] = useState(true);

  // const closeModal = () => {
  //   setIsOpen(false);
  // };
  return (
    <div className="layout">
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Manchester Psych Fest 2024"
      >
        <div className="modal-content">
          <Typography className="title" variant="h6">
            Brighton Psych Fest 2025
          </Typography>
          <Typography className="text">£24.50 early bird ticket</Typography>
          <Typography className="text">Friday 29th August 2025</Typography>

          <Button
            href="https://www.seetickets.com/event/brighton-psych-fest-2025/various-brighton-venues/3146372"
            color="primary"
            size="large"
            variant="contained"
            className="ticket-header-button"
            sx={{
              fontSize: "16px",
              margin: "20px 0",
              textAlign: "center",
            }}
            target="_blank"
          >
            BUY TICKETS NOW
          </Button>

          <Button
            color="secondary"
            size="large"
            variant="contained"
            className="ticket-header-button"
            sx={{
              fontSize: "16px",
              margin: "20px 0",
              textAlign: "center",
            }}
            target="_blank"
            onClick={closeModal}
          >
            Close
          </Button>
        </div>
      </Modal> */}
      {/* <ResponsiveAppBar /> */}
      <div className="main-content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
